export const environment = {
    production: true,
    env: 'prod',
    dsn: 'https://2baeeb6dbd0545198b92320e36d26fcd@o1240364.ingest.sentry.io/4505232456351744',
    envVar: {
        backendUrl: 'https://printportal.cityzmedia.fr/backend/',
        socketUrl: 'wss://websocket.printportal.cityzmedia.fr:443',
    },
    features: {
        batRequestFlowEnabled: true, // This is a feature specific for the French market
        importAddressesEnabled: true,
        weekNumbersEnabled: false,
    }
};
